<template>
    <div class="data-dict">
        <el-cascader
                v-model="partyOrganId"
                :show-all-levels="false"
                ref="sysCascader"
                filterable
                :props="prop"
                @change="handleChange($event)"
                clearable>
        </el-cascader>
    </div>
</template>

<script>
    import {getArea, getAreaNext} from "../api/index";
    export default {
        name: "tree",
        data() {
            return {
                partyOrganId: "",
                isFinishedGetOptions: false,
                questionName: "",
                prop: {
                    lazy: true,
                    checkStrictly: false,
                    expandTrigger: 'hover',
                    lazyLoad(node, resolve) {
                        setTimeout(() => {
                            if (node.level == 0) {
                                const obj = {
                                    ak: "e10adc3949ba59abbe56e057f20f883e"
                                };
                                getArea(obj).then(res => {
                                    let data = Array.from(res.data);
                                        const cities = data.map((value, i) => ({
                                            value: value.code,
                                            label: value.name,
                                            leaf: node.level >= 2
                                        }));
// 通过调用resolve将子节点数据返回，通知组件数据加载完成
                                        resolve(cities);
                                    })
                                    .catch(err => {
                                        console.log(err);
                                    });
                            }
                            if (node.level != 0) {
                                if(node.level == 3){
                                    //忽略
                                    console.log(node)
                                    node.loading = false;
                                }else {
                                    const obj = {
                                        ak: "e10adc3949ba59abbe56e057f20f883e",
                                        pcode: node.value
                                    };
                                    getAreaNext(obj).then(res => {
                                        let data = Array.from(res.data);
                                        const areas = data.map((value, i) => ({
                                            value: value.code,
                                            label: value.name,
                                            leaf: node.level >= 2
                                        }));
// 通过调用resolve将子节点数据返回，通知组件数据加载完成
                                        resolve(areas);
                                    })
                                        .catch(err => {
                                            console.log(err);
                                        });
                                }
                            }
                        }, 100);
                    }
                },
            }
        },
        methods: {
            handleChange(event) {
                let pathvalue = this.$refs.sysCascader.getCheckedNodes()[0];
                console.log(pathvalue)
                if (event.length == 3) {
                    this.questionName = pathvalue.label;
                    this.$refs.sysCascader.dropDownVisible = false;
                }

            }
        }
    }
</script>

<style lang="scss" scoped>
    >>> .loading-cascader {
        line-height: 32px;
        .el-loading-spinner {
            height: 32px;
            line-height: 32px;
            top: 0;
            margin-top: 0;
            text-align: right;
            padding-right: 10px;
        }
    }
    .data-dict {
        position: relative;
        .el-cascader,
        .el-select {
            display: block;
            width: 100%;
        }
    }
    /* cascader 隐藏单选样式 start */
    .el-radio {
        display: none;
    }
    /* cascader 隐藏单选样式 end */
</style>