import { fetchGet,fetchPost} from '../https.js';
//登录
export const login = (p) => {
 return fetchGet('/rcbp/login', p)
};
//首页 获取某个区域统计
export const getTotalByArea = (p) => {
    return fetchGet('/ad/getTotalByArea', p)
};
//首页 获取区域统计
export const getTotalListByArea = (p) => {
    return fetchGet('/ad/getTotalListByArea', p)
};
//首页 获取分类统计
export const getTotalListByCode = (p) => {
    return fetchGet('/ad/getTotalListByCode', p)
};
//首页 新闻
export const getNewsList = (p) => {
    return fetchGet('/ad/getNewsList', p)
};
//首页 新闻详细
export const getNewsInfo = (p) => {
    return fetchGet('/ad/getNewsInfo', p)
};
//搜索
export const getADCompany = (p) => {
    return fetchGet('/ad/getADCompany', p)
};
//分类导航
export const getCategory = (p) => {
    return fetchGet('/ad/getCategory', p)
};
//当前用户所属区域
export const getUserArea = (p) => {
    return fetchGet('/ad/getUserArea', p)
};
//根据区域名称获取区域代码
export const getAreaByName = (p) => {
    return fetchGet('/ad/getAreaByName', p)
};
//根据区域代码获取区域信息
export const getAreaInfo = (p) => {
    return fetchGet('/ad/getAreaInfo', p)
};
//根据区域代码获取区域信息
export const getArea = (p) => {
    return fetchGet('/ad/getArea', p)
};
//根据区域代码获取区域信息
export const getAreaNext = (p) => {
    return fetchGet('/ad/getAreaNext', p)
};

//获取广告服务商详细
export const getCompanyInfo = (p) => {
    return fetchGet('/ad/getCompanyInfo', p)
};