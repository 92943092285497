<template>
  <div id="container">
    <div class="topbg"></div>
    <div class="head">
      <div class="h-left">
        <div class="logo">
          <div><a href="./"><img src="../public/img/logo.png" alt="搜光助手，获取广告服务，助力您的生活与工作！" style="border: none;" /></a></div>
        </div>
      </div>
      <div class="h-middle"></div>
      <div class="h-right">
        <div class="h-info"></div>
        <div class="h-info"></div>
      </div>
    </div>
    <div class="main">
      <div class="left">
        <div>
          <span style="padding-left: 6px"> 区域：{{nowAreaName}}</span><br>
          <el-table ref="table" :data="areaTjData" :show-header="false" v-loading="bindTJDataLoading" highlight-current-row style="width: 100%;" :cell-style="{padding:'0px'}">
            <el-table-column label="">
              <template slot-scope="scope">
                <div>{{scope.row.name}}（{{scope.row.num}}）</div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="ad">
          <img src="../public/ad.png" class="adimg" /><br>
          <img src="../public/ad.png" class="adimg" />
        </div>
      </div>
      <div class="right">
        <div class="search">
          <el-form :model="form" ref="form" :rules="rule" label-width="0px" class="demo-ruleForm">
            <el-input v-model="form.keyword" class="searchInput" placeholder="输入广告服务商名字关键词"></el-input>
            <el-button type="primary" @click="search()" :loading="loadingSearch" icon="el-icon-search">搜索</el-button>
            <el-button type="primary" @click="showSelect()">筛选</el-button>
          </el-form>
        </div>
    <div class="pagingform">
      <el-table ref="table" :data="tableData" :show-header="false" v-loading="bindDataLoading" highlight-current-row style="width: 100%;" :row-style="tableRowStyle" :cell-style="{padding:'0px'}" :header-cell-style="tableHeaderColor">
        <el-table-column label="" width="120" height="100">
          <template slot-scope="scope">
            <img :src="scope.row.photo" style="width: 100px;" v-image-preview />
          </template>
        </el-table-column>
        <el-table-column label="" width="600">
          <template slot-scope="scope">
            <div @click="showDetail(scope.row)" style="cursor: pointer;"><b>{{scope.row.name}}</b></div>
            <div><b>联系电话：</b>{{scope.row.phone}}</div>
            <div><b>地址：</b>{{scope.row.address}}</div>
            <div><b>热度：</b>{{scope.row.hits}}&nbsp;&nbsp;&nbsp;&nbsp;
              <span v-if="scope.row.stars == '0'" title="" class="mid-rank-stars mid-str0"></span>
              <span v-if="scope.row.stars == '1'" title="" class="mid-rank-stars mid-str1"></span>
              <span v-if="scope.row.stars == '2'" title="" class="mid-rank-stars mid-str2"></span>
              <span v-if="scope.row.stars == '3'" title="" class="mid-rank-stars mid-str3"></span>
              <span v-if="scope.row.stars == '4'" title="" class="mid-rank-stars mid-str4"></span>
              <span v-if="scope.row.stars == '5'" title="" class="mid-rank-stars mid-str5"></span>
              <span v-if="scope.row.status == '未开业'" title="" class="snone">未开业</span>
              <span v-if="scope.row.status == '试营业'" title="" class="stest">试营业</span>
              <span v-if="scope.row.status == '正常营业'" title="" class="sok">正常营业</span>
              <span v-if="scope.row.status == '歇业'" title="" class="srelax">歇业</span>
              <span v-if="scope.row.status == '已注销'" title="" class="sclose">已注销</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="paging">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-size="pageSize" layout="total, prev, pager, next, jumper" :total="rowCount"></el-pagination>
      </div>
    </div>
      </div>
    </div>
    <div class="foot">
      CopyRight &copy; 2021 备案/许可证编号：<a href="http://beian.miit.gov.cn/" target="_blank">冀ICP备2021005475号</a>
    </div>
    <el-dialog title="" :visible.sync="showDialog" width="80%" center>
      <el-form :model="selForm" ref="addForm" label-width="0px" class="demo-ruleForm">
      <el-form-item>
        <el-col :span="10" style="ffont-size: 24px;">当前检索条件：</el-col>
        <el-col :span="20" style="ffont-size: 24px;">
          <span v-if="keyword!=''">关键词：<b>{{keyword}}</b>，</span>
          <span v-if="areaName!=''">区域：<b>{{areaName}}</b>，</span>
          <span v-if="codeName!=''">分类：<b>{{codeName}}</b></span>
        </el-col>
      </el-form-item>
        <el-form-item>
          <el-col :span="4">选择区域：</el-col>
          <el-col :span="15">
            <el-cascader
                    v-model="selForm.area"
                    :show-all-levels="false"
                    ref="sysCascader"
                    filterable
                    :props="prop"
                    @change="handleChange($event)"
                    clearable>
            </el-cascader>
          </el-col>
        </el-form-item>
        <el-form-item>
          <el-col :span="4">选择类别：</el-col>
          <el-col :span="15">
            <el-cascader
                    v-model="selForm.code"
                    :show-all-levels="false"
                    ref="sysCategory"
                    filterable
                    :props="category"
                    @change="handleCategoryChange($event)"
                    clearable>
            </el-cascader>
          </el-col>
        </el-form-item>
      </el-form>
      <div style="text-align: center; margin-top:10px;">
        <el-button type="primary" @click="submit()" :loading="loading">确 定</el-button>
        <el-button @click="showDialog = false">关闭窗口</el-button>
      </div>
    </el-dialog>

    <el-dialog title="" :visible.sync="showDetailDialog" width="80%" center>
      <el-row>
        <el-col :span="20" style="font-weight: bold; font-size: 24px;">{{name}}</el-col>
      </el-row>
      <el-row>
        <el-col :span="20">联系电话：{{phone}}</el-col>
      </el-row>
      <el-row>
        <el-col :span="20">联系地址：{{address}}</el-col>
      </el-row>
      <el-row>
        <el-col :span="20" style="line-height: 24px;">服务商简介：<br />{{message}}</el-col>
      </el-row>
      <el-row>
        <el-col :span="20" style="line-height: 24px;">经营项目：<br />{{content}}</el-col>
      </el-row>
      <el-row>
        <div style="width:100%;height:450px;border:#ccc solid 1px;" id="dituContent"></div>
      </el-row>
      <div style="text-align: center; margin-top:10px;">
        <el-button @click="showDetailDialog = false">关闭窗口</el-button>
      </div>
    </el-dialog>
    </div>
</template>

<script>
  import { getADCompany,getAreaInfo,getArea, getAreaNext,getCategory,getCompanyInfo,getTotalListByArea} from "./api/index";

export default {
  name: 'Search',
  data(){
    return{
      loading: false,
      bindDataLoading: false,
      loadingSearch: false,
      showDialog: false,
      showDetailDialog: false,
      bindTJDataLoading: false,
      areaTjData: [],
      tableData: [],
      pageSize: 10,
      currentPage: 1,
      rowCount: 1,
      pageIndex: 1,
      ak: this.GLOBAL.ak,
      keyword: "",
      area: "",
      areaName: "",
      nowAreaName: "",
      code: "",
      codeName: "",
      form:{
        keyword: ""
      },
      rule:{
        keyword: [
          { required: true, message: '请输入关键词', trigger: 'blur' }
        ]
      },
      selForm:{
        area: "",
        code: ""
      },
      prop: {
        lazy: true,
        checkStrictly: false,
        expandTrigger: 'hover',
        lazyLoad(node, resolve) {
          setTimeout(()=> {
            let ak = "e10adc3949ba59abbe56e057f20f883e";//引用data定义内容  undefined
            if (node.level == 0) {
              const obj = {
                ak: ak
              };
              getArea(obj).then(res => {
                let data = Array.from(res.data);
                const cities = data.map((value, i) => ({
                  value: value.code,
                  label: value.name,
                  leaf: node.level >= 2
                }));
                // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                resolve(cities);
              })
                      .catch(err => {
                        console.log(err);
                      });
            }
            if (node.level != 0) {
              if(node.level == 3){
                //忽略
                node.loading = false;
              }else {
                const obj = {
                  ak: ak,
                  pcode: node.value
                };
                getAreaNext(obj).then(res => {
                  let data = Array.from(res.data);
                  const areas = data.map((value, i) => ({
                    value: value.code,
                    label: value.name,
                    leaf: node.level >= 2
                  }));
                  // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                  resolve(areas);
                })
                        .catch(err => {
                          console.log(err);
                        });
              }
            }
          }, 100);
        }
      },
      category: {
        lazy: true,
        checkStrictly: false,
        expandTrigger: 'hover',
        lazyLoad(node, resolve) {
          setTimeout(()=> {
            let ak = "e10adc3949ba59abbe56e057f20f883e";//引用data定义内容  undefined
            if (node.level == 0) {
              const obj = {
                ak: ak
              };
              getCategory(obj).then(res => {
                let data = Array.from(res.data);
                const cities = data.map((value, i) => ({
                  value: value.code,
                  label: value.name,
                  leaf: node.level >= 1
                }));
                // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                resolve(cities);
              })
                      .catch(err => {
                        console.log(err);
                      });
            }
            if (node.level != 0) {
              if(node.level == 2){
                //忽略
                node.loading = false;
              }else {
                const obj = {
                  ak: ak,
                  parentcode: node.value
                };
                getCategory(obj).then(res => {
                  let data = Array.from(res.data);
                  const areas = data.map((value, i) => ({
                    value: value.code,
                    label: value.name,
                    leaf: node.level >= 1
                  }));
                  // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                  resolve(areas);
                })
                        .catch(err => {
                          console.log(err);
                        });
              }
            }
          }, 100);
        }
      },
      name: "",
      phone: "暂无",
      address: "",
      message: "暂无",
      content: "暂无",
      mapX: 0,
      mapY: 0,
      mapContent: []
    }
  },
  create(){
    this.bind();
    this.showSelect();//检索条件界面
    this.areaTJ();
  },
  mounted() {
    if(this.$route.params.keys != undefined) {
      this.keyword = this.$route.params.keys;
      this.form.keyword = this.$route.params.keys;
    }
    if(this.$route.params.area != undefined){
      this.area = this.$route.params.area;
      var obj = {
        ak: this.ak,
        code: this.$route.params.area
      }
      getAreaInfo(obj).then(res=>{
        if(res.code == 0 && res.data != ""){
          this.areaName = res.data[0].name;
          this.nowAreaName = res.data[0].name;
        }
      });
    }else if(this.$route.params.code != undefined){
      this.code = this.$route.params.code;
    }else{
      if(this.$cookies.get("areaCode") !== undefined){
        if(this.$cookies.get("areaCode") != null){
          this.area = this.$cookies.get("areaCode");
          this.areaName = this.$cookies.get("areaName");
          this.nowAreaName = this.$cookies.get("areaName").split("-")[2];
        }
      }
    }

    this.bindDataLoading = true;
    this.bind();
    this.areaTJ();
    this.bindDataLoading = false;
  },
  methods:{
    getAK(){
      let that = this;
      return that.ak;
    },
    showSelect(){
      this.showDialog = true;
    },
    submit(){
      this.showDialog = false;
    },
    areaTJ() {
      this.bindTJDataLoading = true;
      var  tjCode = this.area;
      if(tjCode != "" && tjCode.length > 3){
        tjCode = tjCode.substring(0,4);
      }
      var obj = {
        ak: this.ak,
        code: tjCode
      };
      getTotalListByArea(obj).then(res => {
        if(res.code == 0 && res.data != "") {
          this.areaTjData = res.data;
        }else{
          this.areaTjData = [];
        }
        this.bindTJDataLoading = false;
      }).catch(() => {
        this.bindTJDataLoading = false;
      });
    },
    showDetail(row){
      this.showDetailDialog = true;
      var obj = {
        ak: this.ak,
        infoid: row.id
      }
      getCompanyInfo(obj).then(res=>{
        if(res.code == 0 && res.data != ""){
          this.name = res.data[0].name;
          if(res.data[0].phone != "") {
            this.phone = res.data[0].phone;
          }else{
            this.phone = "暂无";
          }
          this.address = res.data[0].address;
          if(res.data[0].message != "") {
            this.message = res.data[0].message;
          }else{
            this.message = "暂无";
          }
          if(res.data[0].content != "") {
            this.content = res.data[0].content;
          }else{
            this.content = "暂无";
          }
          this.mapX = parseFloat(res.data[0].gpsX);
          this.mapY = parseFloat(res.data[0].gpsY);
          this.mapContent = [{title:"" + res.data[0].name + "",content:"地址：" + res.data[0].address + "<br/>电话：" + res.data[0].phone + "",point:"" + res.data[0].gpsX + "|" + res.data[0].gpsY + "",isOpen:1,icon:{w:23,h:25,l:46,t:21,x:9,lb:12}}];
          this.initMap();
        }else{
          this.showDetailDialog = false;
          this.$message({
            type: "info",
            message: "未找到相关信息！"
          });
        }
      }).catch(() =>{
        this.showDetailDialog = false;
        this.$message({
          type: "info",
          message: "未找到相关信息！"
        });
      })
    },
    search() {
      this.loadingSearch = true;

      if(this.form.keyword != ""){
        this.loadingSearch = false;
        this.keyword = this.form.keyword;
        this.bind();
      }else {
        this.loadingSearch = false;
        this.$message({
          type: "info",
          message: "请输入要查询的内容！"
        });
      }
    },
    bind(){
      if(this.selForm.area != "" && this.selForm.code != ""){
        var obj = {
          ak: this.GLOBAL.ak,
          name: this.keyword,
          areacode: this.selForm.area,
          code: this.selForm.code,
          pageSize: this.pageSize,
          pageIndex: this.pageIndex
        }
      }else if(this.selForm.area != "" && this.selForm.code == ""){
        obj = {
          ak: this.GLOBAL.ak,
          name: this.keyword,
          areacode: this.selForm.area,
          pageSize: this.pageSize,
          pageIndex: this.pageIndex
        }
      }else if(this.selForm.area == "" && this.selForm.code != ""){
        obj = {
          ak: this.GLOBAL.ak,
          name: this.keyword,
          code: this.selForm.code,
          pageSize: this.pageSize,
          pageIndex: this.pageIndex
        }
      }else{
        obj = {
          ak: this.GLOBAL.ak,
          name: this.keyword,
          areacode: this.area,
          code: this.code,
          pageSize: this.pageSize,
          pageIndex: this.pageIndex
        }
      }
      getADCompany(obj).then(res=>{
        if(res.code == 0 && res.data != ""){
          for(var i=0;i<res.data.list.length;i++){
            if(res.data.list[i].photo == "") {
              res.data.list[i].photo = this.GLOBAL.fileURL + "/sys/empty.png";
            }else{
              res.data.list[i].photo = this.GLOBAL.fileURL + res.data.list[i].photo;
            }
          }
          this.tableData = res.data.list;
          this.rowCount = res.data.page.rowCount;
          this.currentPage = res.data.page.pageIndex;
          this.loadingSearch = false;
        }
      }).catch(() => {
        this.loadingSearch = false;
      });
    },
    handleCurrentChange(val) {
      this.pageIndex = val;
      this.bind(val);
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    //地区选择
    handleChange(event) {
      let pathvalue = this.$refs.sysCascader.getCheckedNodes()[0];
      if (event.length == 3) {
        this.areaName = pathvalue.parent.parent.label + " - " + pathvalue.parent.label + " - " + pathvalue.label;
        this.selForm.area = pathvalue.value;
        this.$refs.sysCascader.dropDownVisible = false;
      }
    },
    //分类选择
    handleCategoryChange(event) {
      let pathvalue = this.$refs.sysCategory.getCheckedNodes()[0];
      if (event.length == 2) {
        this.codeName = pathvalue.parent.label + "/" + pathvalue.label;
        this.selForm.code = pathvalue.value;
        this.$refs.sysCascader.dropDownVisible = false;
      }
    },
    //设置表格行的样式
    tableRowStyle(){
      return 'font-size:12px;'
    },
    //设置表头行的样式
    tableHeaderColor(){
      return 'font-size:12px;'
    },
    //这几个地方加this
    initMap () {
      this.createMap() ; //创建地图
      this.setMapEvent();//设置地图事件
      this.addMapControl();//向地图添加控件
      this.addMarker();//向地图中添加marker
    },
    createMap(){
      var map = new BMap.Map("dituContent");//在百度地图容器中创建一个地图
      var point = new BMap.Point(this.mapX,this.mapY);//定义一个中心点坐标
      map.centerAndZoom(point,14);//设定地图的中心点和坐标并将地图显示在地图容器中
      window.map = map;//将map变量存储在全局
    },
    setMapEvent(){
      map.enableDragging();//启用地图拖拽事件，默认启用(可不写)
      map.enableScrollWheelZoom();//启用地图滚轮放大缩小
      map.enableDoubleClickZoom();//启用鼠标双击放大，默认启用(可不写)
      map.enableKeyboard();//启用键盘上下左右键移动地图
    },
    addMapControl(){
      //向地图中添加缩放控件
      var ctrl_nav = new BMap.NavigationControl({anchor:BMAP_ANCHOR_TOP_LEFT,type:BMAP_NAVIGATION_CONTROL_LARGE});
      map.addControl(ctrl_nav);
      //向地图中添加缩略图控件
      var ctrl_ove = new BMap.OverviewMapControl({anchor:BMAP_ANCHOR_BOTTOM_RIGHT,isOpen:1});
      map.addControl(ctrl_ove);
      //向地图中添加比例尺控件
      var ctrl_sca = new BMap.ScaleControl({anchor:BMAP_ANCHOR_BOTTOM_LEFT});
      map.addControl(ctrl_sca);
    },
    //标注点数组
    //创建marker
    addMarker(){
      var markerArr = this.mapContent;
      for(var i=0;i<markerArr.length;i++){
        var json = markerArr[i];
        var p0 = json.point.split("|")[0];
        var p1 = json.point.split("|")[1];
        var point = new BMap.Point(p0,p1);
        //这个地方加this
        var iconImg = this.createIcon(json.icon);
        var marker = new BMap.Marker(point,{icon:iconImg});
        //这个地方加this
        var iw = this.createInfoWindow(i);
        var label = new BMap.Label(json.title,{"offset":new BMap.Size(json.icon.lb-json.icon.x+10,-20)});
        marker.setLabel(label);
        map.addOverlay(marker);
        label.setStyle({
          borderColor:"#808080",
          color:"#333",
          cursor:"pointer"
        });
        var index = i;
        //这个地方加this
        var _iw = this.createInfoWindow(i);
        var _marker = marker;
        marker.addEventListener("click",function(){
          //这个地方加this
          this.openInfoWindow(_iw);
        });
        iw.addEventListener("open",function(){
          _marker.getLabel().hide();
        })
        _iw.addEventListener("close",function(){
          _marker.getLabel().show();
        })
        label.addEventListener("click",function(){
          _marker.openInfoWindow(_iw);
        })
        if(!!json.isOpen){
          label.hide();
          _marker.openInfoWindow(_iw);
        }
      }
    },
    //创建InfoWindow
    createInfoWindow(i){
      //这个地方复制一下上面的var markerArr 不然会不显示报错
      var markerArr = this.mapContent;//[{title:"无锡云熵动力科技有限公司",content:"地址：无锡市惠山经济开发区智慧路18号<br/>电话：15061509527",point:"120.32801|31.686872",isOpen:1,icon:{w:23,h:25,l:46,t:21,x:9,lb:12}}];
      var json = markerArr[i];
      var iw = new BMap.InfoWindow("<b class='iw_poi_title' title='" + json.title + "'>" + json.title + "</b><div class='iw_poi_content'>"+json.content+"</div>");
      return iw;
    },
    //创建一个Icon
    createIcon(json){
      //这个地方我是用本地图标图片的
      var tubiao=require("./assets/mkicon.png")
      var icon = new BMap.Icon(tubiao, new BMap.Size(json.w,json.h),{imageOffset: new BMap.Size(-json.l,-json.t),infoWindowOffset:new BMap.Size(json.lb+5,1),offset:new BMap.Size(json.x,json.h)})
      return icon;
    }
  },
  components: {

  },
  watch: {
    '$route' (to, from) {
      if(to.params.keys != from.params.keys){
        this.keyword = to.params.keys;
      }
      if(to.params.area != from.params.area){
        this.area = to.params.area;
        this.bind();//重新加载数据
      }
      if(to.params.code != from.params.code){
        this.area = to.params.area;
        this.bind();//重新加载数据
      }
      this.bind();//重新加载数据
    }
  }
}
</script>

<style>
  .custom-tree-node {
    width: 100%;
    height: 100%;
  }
  .custom-tree-node .total_info_box {
    background: #f2f2f2;
    line-heght: 30px;
  }
  .custom-tree-node .total_info_box > span {
    float: left;
    font-size: 12px;
    margin: 0 15px;
  }
  .custom-tree-node .total_info_box > span > i {
    display: inline-block;
    margin-right: 3px;
  }
  .custom-tree-node .table_info_node {
    display: flex;
    height: 100%;
    text-align: center;
  }
  .custom-tree-node table_info_node .table_info_item {
    flex: 1;
    height: 100%;
    float: left;
    boeder-left: 1px solid #ebeef5;
    padding-left: 10px;
    line-height: 30px;
  }
  .clearfix:before .clearfix:after {
    content: "";
    display: table;
  }
  .clearfix:after {
    clear: both;
  }
  .clearfix {
    *zoom: 1;
  }
</style>
