<template>
  <div id="container">
      <div class="topbg"></div>
      <div class="head">
        <div class="h-left">
            <div class="logo">
                <div><a href="./"><img src="../public/img/logo.png" alt="搜光助手，获取广告服务，助力您的生活与工作！" style="border: none;" /></a></div>
            </div>
        </div>
        <div class="h-middle"></div>
        <div class="h-right">
            <div class="h-info"></div>
            <div class="h-info"></div>
        </div>
      </div>
      <div class="main">
        <div class="left">
            <div class="title">
                <div class="dot"><img src="../public/img/article.png" style="width: 16px;" /></div>
                <div class="fontName">行业内容</div>
            </div>
            <div class="newTable">
                <el-table ref="table" :data="newsData" :row-style="tableRowStyle" :show-header="false" v-loading="dataNewsLoading" style="border: none; width: 100%;" :cell-style="{padding:'0px'}">
                    <el-table-column prop="name" label="名称" width="230" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            <span @click="show(scope.row)" style="cursor: pointer">{{scope.row.title}}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="ad">
                <img src="../public/ad.png" class="adimg" /><br>
                <img src="../public/ad.png" class="adimg" />
            </div>
            <div class="title">
                <div class="dot"><img src="../public/img/link.png" style="width: 16px;" /></div>
                <div class="fontName">业务咨询</div>
            </div>
            <div class="access">
                <span class="mark">广告发布</span>
                <span class="mark">网站建设</span><br />
                <span class="mark">技术咨询</span>
                <span class="mark">软件/系统开发</span>
            </div>
            <div class="linkstyle">
                <b>联系我们</b><br />
                电子邮箱：adself@163.com<br />
                QQ：<a href="http://wpa.qq.com/msgrd?v=3&uin=1767589653&site=qq&menu=yes" target="_black">1767589653</a>
            </div>
        </div>
        <div class="right">
            <div class="search">
                <el-form :model="form" ref="form" :rules="rule" label-width="0px" class="demo-ruleForm">
                    <el-input v-model="form.keyword" class="searchInput" placeholder="输入广告服务商名字关键词"></el-input>
                    <el-button type="primary" @click="search()" :loading="loadingSearch" icon="el-icon-search">搜索</el-button>
                </el-form>
            </div>
            <div class="gis">当前位置：{{userArea}}</div>
            <div class="tj">
                <div class="tjtitle">
                    <div class="tjdot"><img src="../public/img/dote.png" /> </div>
                    <div class="tjfont">区域统计</div>
                </div>
                <div class="tjcontent" :loading="loading">
                    <el-row :gutter="10">
                        <el-link v-for="item in areaData" :key="item.url" @click="search('0,' + item.url)">
                            {{item.name}}（{{item.num}}）
                        </el-link>
                    </el-row>
                </div>
            </div>
            <div class="tjad"><img src="../public/ad1.png"></div>
            <div class="tj">
                <div class="tjtitle">
                    <div class="tjtitle">
                        <div class="tjdot"><img src="../public/img/dote.png" /> </div>
                        <div class="tjfont">分类统计</div>
                    </div>
                </div>
                <div class="tjcontent" :loading="loadingCode">
                    <el-row :gutter="10">
                        <el-link v-for="item in categoryData" :key="item.url" @click="search('1,' + item.url)">
                            {{item.name}}（{{item.num}}）
                        </el-link>
                    </el-row>
                </div>
            </div>
        </div>
      </div>
      <div class="foot">
          <div class="fl"><span @click="showText()">【声明】</span></div>
          <div class="fr">CopyRight &copy; 2021 备案/许可证编号：<a href="http://beian.miit.gov.cn/" target="_blank">冀ICP备2021005475号</a></div>
      </div>
      <el-dialog title="" :visible.sync="showDetailDialog" width="80%" center>
          <el-row>
              <el-col :span="20" style="font-weight: bold; font-size: 24px;">{{title}}</el-col>
          </el-row>
          <el-row>
              <el-col :span="20">发布者：{{own}} 时间：{{addtime}} 浏览量：{{hits}}</el-col>
          </el-row>
          <el-row>
              <el-col v-html="content" styloe="line-height:24px;"></el-col>
          </el-row>
          <div style="text-align: center; margin-top:10px;">
              <el-button @click="showDetailDialog = false">关闭窗口</el-button>
          </div>
      </el-dialog>
      <el-dialog title="" :visible.sync="showTextDialog" width="80%" center>
          <el-row>
              <el-col :span="20" style="font-weight: bold; font-size: 24px;">声明</el-col>
          </el-row>
          <el-row>
              <el-col v-html="shengming" styloe="line-height:24px;"></el-col>
          </el-row>
          <div style="text-align: center; margin-top:10px;">
              <el-button @click="showTextDialog = false">关闭窗口</el-button>
          </div>
      </el-dialog>
  </div>
</template>

<script>
    import { getTotalListByArea,getTotalListByCode,getNewsList,getNewsInfo,getUserArea,getAreaByName} from "./api/index";
// var Base64 = require('js-base64').Base64;
export default {
    name: "index",
  data() {
    return {
        dataNewsLoading: false,
        loading: false,
        loadingCode: false,
        loadingSearch: false,
        showDetailDialog: false,
        showTextDialog: false,
        ak: this.GLOBAL.ak,
        userArea: "",
        newsData: [],
        areaData: [],
        categoryData: [],
        form:{
          keyword: ""
        },
        rule:{
          keyword: [
          { required: true, message: '请输入关键词', trigger: 'blur' }
        ]
      },
        title: "",
        hits: "",
        content: "",
        own: "",
        addtime: "",
        shengming: ""
    };
  },
  created(){
      // this.teststr = Base64.encode("123456");
      this.bind();//区域统计数据
      this.category();//分类统计数据
      this.news();//行业报告数据
      this.userAddr();
  },
  methods: {
        showText(){
          this.showTextDialog = true;
          this.shengming = "1、本网站为免费提供广告相关服务内容的网站<br />2、在本网站发布的信息（服务商、广告及相关服务等）均需符合国家法律法规相关规定<br />3、非绿色、健康、环保等信息或广告不予受理<br />4、本网站接受捐赠支持等。";
        },
        userAddr(){
            if(this.$cookies.get("areaName") !== undefined){
                if(this.$cookies.get("areaName") != "" && this.$cookies.get("areaName") != null){
                    this.userArea = this.$cookies.get("areaName");
                }else{
                    var obj = {
                        ak: this.ak
                    }
                    getUserArea(obj).then(res=>{
                        if(res.code == 0 && res.data != ""){
                            if(res.data.ret == "ok"){
                                this.userArea = res.data.data[1] + " - " + res.data.data[2] + " - " + res.data.data[3];
                                this.$cookies.set("areaName",res.data.data[1] + " - " + res.data.data[2] + " - " + res.data.data[3]);
                                //获取区域码
                                var objArea = {
                                    ak: this.ak,
                                    area: res.data.data[3]
                                }
                                getAreaByName(objArea).then(result=>{
                                    if(result.code == 0 && result.data != ""){
                                        if(result.data.length > 0){
                                            this.$cookies.set("areaCode", result.data[0].code);
                                        }
                                    }
                                })
                            }
                        }
                    })
                }
            }else{
                this.$message({
                    type: "info",
                    message: "未有效开启cookie，某些功能将受到限制！"
                });
            }
        },
    show(row) {
      this.showDetailDialog = true;
      var obj = {
          ak: this.ak,
          infoid: row.infoid
      };
      getNewsInfo(obj).then(res => {
          if(res.code == 0 && res.data != "") {
              this.title = row.title;
              this.own = row.own;
              this.hits = res.data[0].hits;
              this.addtime = row.addtime;
              this.content = row.content;
          }else{
              this.$message({
                  type: "info",
                  message: "数据不存在！"
              });
          }
      }).catch(() => {
          this.$message({
              type: "info",
              message: "数据不存在！"
          });
      });
    },
    search(obj) {
      this.loadingSearch = true;
      if(obj != null){
          var arr = obj.split(",");
          if(arr[0] == "0"){
              this.$router.push({name: 'search', params: {area: arr[1]}});
          }else{
              this.$router.push({name: 'search', params: {code: arr[1]}});
          }
      }else {
          if (this.form.keyword != "") {
              this.loadingSearch = false;
              this.$router.push({name: 'search', params: {keys: this.form.keyword}});
          } else {
              this.loadingSearch = false;
              this.$message({
                  type: "info",
                  message: "请输入要查询的内容！"
              });
          }
      }
    },
    bind() {
        this.loading = true;
        var obj = {
          ak: this.ak
        };
      getTotalListByArea(obj).then(res => {
          // this.areaData = "";
          if(res.code == 0 && res.data != "") {
              this.areaData = res.data;
          }else{
              this.areaData = "暂无相关数据！";
          }
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
      category() {
          this.loadingCode = true;
          var obj = {
              ak: this.GLOBAL.ak
          };
          getTotalListByCode(obj).then(res => {
              if(res.code == 0 && res.data != "") {
                  this.categoryData = res.data;
              }else{
                  this.categoryData = [];
              }
              this.loadingCode = false;
          }).catch(() => {
              this.loadingCode = false;
          });
      },
      news(){
          this.dataNewsLoading = true;
          var obj = {
              ak: this.GLOBAL.ak
          };
          getNewsList(obj).then(res => {
              if(res.code == 0 && res.data != "") {
                  this.newsData = res.data;
              }else{
                  this.newsData = [];
              }
              this.dataNewsLoading = false;
          }).catch(() => {
              this.dataNewsLoading = false;
          });
      },
      tableRowStyle({row,rowIndex}){
          return 'border: none;'
      },
      mounted () {
          this.city()
      },
  }
}
</script>

<style>
    .el-table tbody tr:hover>td {
        background-color:#ffffff!important
    }
    .el-table__row>td{
         border: none;
     }
    .el-table::before {
    height: 0px;
    }

    .el-row {
        margin-bottom: 20px;
    &:last-child {
         margin-bottom: 0;
     }
    }
    .el-col {
        border-radius: 4px;
    }
    .bg-purple-dark {
        background: #99a9bf;
    }
    .bg-purple {
        background: #d3dce6;
    }
    .bg-purple-light {
        background: #e5e9f2;
    }
    .grid-content {
        border-radius: 4px;
        min-height: 36px;
    }
    .row-bg {
        padding: 10px 0;
        background-color: #f9fafc;
    }

</style>
